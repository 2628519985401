// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-hfe-mentors-js": () => import("./../../../src/pages/about-hfe-mentors.js" /* webpackChunkName: "component---src-pages-about-hfe-mentors-js" */),
  "component---src-pages-about-us-host-family-english-mystay-international-js": () => import("./../../../src/pages/about-us/host-family-english-mystay-international.js" /* webpackChunkName: "component---src-pages-about-us-host-family-english-mystay-international-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-about-us-our-experience-js": () => import("./../../../src/pages/about-us/our-experience.js" /* webpackChunkName: "component---src-pages-about-us-our-experience-js" */),
  "component---src-pages-agents-js": () => import("./../../../src/pages/agents.js" /* webpackChunkName: "component---src-pages-agents-js" */),
  "component---src-pages-contact-us-business-enquiries-js": () => import("./../../../src/pages/contact-us/business-enquiries.js" /* webpackChunkName: "component---src-pages-contact-us-business-enquiries-js" */),
  "component---src-pages-contact-us-host-enquiries-js": () => import("./../../../src/pages/contact-us/host-enquiries.js" /* webpackChunkName: "component---src-pages-contact-us-host-enquiries-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-contact-us-other-enquiries-js": () => import("./../../../src/pages/contact-us/other-enquiries.js" /* webpackChunkName: "component---src-pages-contact-us-other-enquiries-js" */),
  "component---src-pages-education-providers-js": () => import("./../../../src/pages/education-providers.js" /* webpackChunkName: "component---src-pages-education-providers-js" */),
  "component---src-pages-faqs-js": () => import("./../../../src/pages/faqs.js" /* webpackChunkName: "component---src-pages-faqs-js" */),
  "component---src-pages-homestay-organizations-js": () => import("./../../../src/pages/homestay-organizations.js" /* webpackChunkName: "component---src-pages-homestay-organizations-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-the-importance-of-a-mentor-role-js": () => import("./../../../src/pages/the-importance-of-a-mentor-role.js" /* webpackChunkName: "component---src-pages-the-importance-of-a-mentor-role-js" */)
}

